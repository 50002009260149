import { useTranslation } from "react-i18next";

import MaintenanceWrapper from "./maintenance.styles";

import logo from "../../assets/logo.svg";

const Maintenance = () => {
  const { t } = useTranslation();

  return (
    <MaintenanceWrapper>
      <div className="maintenance-container">
        <img src={logo} alt="BuildingHub Logo" />

        <h1>{t("global.maintenancePageTitle")}</h1>

        <p>{t("global.maintenanceSubTitleTitle")}</p>

        <p>
          {t("global.maintenanceDescription")}
          <a
            href={`mailto:${t("global.supportEmail")}`}
            target="_blank"
            rel="noreferrer"
          >
            {t("global.supportEmail")}
          </a>
        </p>
      </div>
    </MaintenanceWrapper>
  );
};

export default Maintenance;
