import i18next from "i18next";
import { initReactI18next } from "react-i18next";

import defaultTranslation from "../../common/locales/en";

const init = (translation: Record<string, any> = defaultTranslation) => {
  i18next.use(initReactI18next).init({
    lng: "en",
    fallbackLng: "en",
    resources: {
      en: {
        translation,
      },
    },
    interpolation: {
      escapeValue: false,
    },
  });
};

export default init;
