type TranslationStructure = Record<
  string,
  Record<string, string | null | undefined> | null | undefined
> | null;

const transformTranslations = (
  sources?: TranslationStructure,
  translations?: TranslationStructure
): Record<string, Record<string, string>> | null => {
  const transformedTranslations: Record<string, Record<string, string>> = {};

  const transform = (translation?: TranslationStructure) => {
    if (translation && Object.keys(translation).length) {
      for (const [outerKey, outerValue] of Object.entries(translation)) {
        if (
          outerKey !== "__typename" &&
          outerValue &&
          typeof outerValue === "object"
        ) {
          for (const [innerKey, innerValue] of Object.entries(outerValue)) {
            if (innerKey !== "__typename" && innerValue) {
              if (!transformedTranslations[outerKey]) {
                transformedTranslations[outerKey] = {};
              }

              transformedTranslations[outerKey][innerKey] = innerValue;
            }
          }
        }
      }
    }
  };

  // First we need to transform sources to not have null values
  transform(sources);

  // Then we need to transform translations to not have null values
  // We can not just merge them all together and then filter out null values
  // Because translation.translations might be null and would override the sources
  transform(translations);

  return Object.keys(transformedTranslations).length
    ? transformedTranslations
    : null;
};

export default transformTranslations;
