import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

import { Title } from "../title";
import { Description } from "../description";
import { Button } from "../button";

import NotFoundWrapper from "./not-found.styles";

import { ReactComponent as Logo } from "../../assets/logo.svg";
import { ReactComponent as NotFoundLogo } from "../../assets/404-logo.svg";

import { INotFoundProps } from "./not-found.interfaces";

const NotFound = ({
  url,
  title,
  text,
  buttonText,
  buttonOnClick,
  hideButton,
  insideLayout,
}: INotFoundProps) => {
  const { t } = useTranslation();

  const history = useHistory();

  return (
    <NotFoundWrapper insideLayout={insideLayout}>
      <div className="logo-wrapper">
        <NotFoundLogo />
      </div>

      <div className="text-wrapper">
        <Title className="mb-12">
          {title ? title : t("global.notFoundTitle")}
        </Title>

        <Description>
          {text ? text : t("global.notFoundDescription")}
        </Description>
      </div>

      {hideButton ? null : (
        <Button
          $size="large"
          variant="primary"
          className="mb-12"
          onClick={
            buttonOnClick ? buttonOnClick : () => history.push(url || "/login")
          }
        >
          {buttonText || t("global.goToHomepage")}
        </Button>
      )}

      <div className="footer-wrapper">
        <Logo />
        {t("global.copyright", { year: dayjs().format("YYYY") })}
      </div>
    </NotFoundWrapper>
  );
};

export default NotFound;
