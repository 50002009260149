import { makeVar } from "shared/providers/apollo/apollo.package";

export interface ILanguageOptionVar {
  id: string;
  title: string;
  countryCode: string;
  translations: Record<string, Record<string, string>>;
}

/**
 * Store available languages for the portfolio (returned from API).
 * We store them in the variable to avoid extra requests to the API as we get those when validating Portfolio URL (on app initialization).
 */
export const languageOptionsVar = makeVar<ILanguageOptionVar[]>([]);
