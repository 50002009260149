import i18next from "i18next";

const changeLanguage = (language: string, resources: Record<string, any>) => {
  // We need to remove the resource bundle first to avoid overriding or merging the existing translations as deep: true, overwrite: true in addResourceBundle doesnt really work
  i18next.removeResourceBundle(language, "translation");
  i18next.addResourceBundle(language, "translation", resources, true, true);
  i18next.changeLanguage(language);
};

export default changeLanguage;
