import React, { useMemo, useState } from "react";
import { createRoot } from "react-dom/client";

import { APIProvider as MapProvider } from "@vis.gl/react-google-maps";

import {
  ApolloProvider,
  ApolloClient,
  ApolloLink,
} from "shared/providers/apollo/apollo.package";
import apolloCache from "./common/apollo/cache";
import apolloSplitLink from "./common/apollo/split-link";
import apolloErrorLink from "./common/apollo/error-link";

import RouterProvider from "shared/providers/router/router.provider";
import StylesProvider from "shared/providers/styles.provider";
import DayjsProvider from "shared/providers/dayjs/dajys.provider";
import I18Provider from "shared/providers/i18next/i18next.provider";

import { HelmetProvider } from "react-helmet-async";

import { App } from "./components/app";

import initI18next from "shared/utils/i18next/init";
import reportWebVitals from "./reportWebVitals";

import translation from "./common/locales/en";

const googleMapsLibraries = ["places", "marker"];

initI18next(translation);

const RootComponent = () => {
  const [isMaintenance, setIsMaintenance] = useState(false);

  const apolloClient = useMemo(
    () =>
      new ApolloClient({
        link: ApolloLink.from([
          apolloErrorLink(setIsMaintenance),
          apolloSplitLink,
        ]),
        cache: apolloCache,
      }),
    []
  );

  return (
    <React.StrictMode>
      <StylesProvider>
        <I18Provider>
          <HelmetProvider>
            <ApolloProvider client={apolloClient}>
              <RouterProvider>
                <DayjsProvider>
                  <MapProvider
                    apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY || ""}
                    libraries={googleMapsLibraries}
                  >
                    <App isMaintenance={isMaintenance} />
                  </MapProvider>
                </DayjsProvider>
              </RouterProvider>
            </ApolloProvider>
          </HelmetProvider>
        </I18Provider>
      </StylesProvider>
    </React.StrictMode>
  );
};

const root = createRoot(document.getElementById("app")!);

root.render(<RootComponent />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
