import { ModalWrapper } from "../../components/modal";
import { Spinner } from "../../components/spinner";

import { ISpinnerModalProps } from "./spinner-modal.interfaces";

const SpinnerModal = ({ $width = "auto", $height }: ISpinnerModalProps) => {
  return (
    <ModalWrapper $width={$width} $height={$height}>
      <div className="modal__content-wrapper">
        <Spinner className="pt-24 pr-24 pb-24 pl-24" />
      </div>
    </ModalWrapper>
  );
};

export default SpinnerModal;
