import { useTranslation } from "react-i18next";

import { Description } from "../description";
import { Title } from "../title";
import { Separator } from "../separator";
import { ExternalLink } from "../external-link";

import UserInactiveWrapper from "./user-inactive.styles";

const UserInactive = () => {
  const { t } = useTranslation();

  return (
    <UserInactiveWrapper>
      <div className="inactive-wrapper">
        <div className="inactive-wrapper__content">
          <div className="inactive-wrapper__content__block">
            <Title level={3} className="mb-10">
              {t("global.accountInactiveTitle")}
            </Title>

            <Description>{t("global.accountInactiveDescription")}</Description>
          </div>

          <Separator />

          <div className="inactive-wrapper__content__block">
            <ExternalLink
              href={`mailto:${t("global.supportEmail")}`}
              target="_blank"
              rel="noreferrer"
            >
              {t("global.supportEmail")}
            </ExternalLink>
          </div>
        </div>
      </div>
    </UserInactiveWrapper>
  );
};

export default UserInactive;
