import { useEffect, useState, Fragment, PropsWithChildren } from "react";
import { useParams, useHistory } from "shared/providers/router/router.package";
import { toast } from "shared/providers/toast/toast.package";

import { useReactiveVar } from "shared/providers/apollo/apollo.package";
import {
  propertyIdVar,
  resetPropertyIdVar,
} from "../../common/apollo/vars/property-id.var";
import { propertySwitchingVar } from "../../common/apollo/vars/property-switching.var";
import {
  propertyDataVar,
  resetPropertyDataVar,
} from "../../common/apollo/vars/property-data.var";
import {
  userPropertyDataVar,
  resetUserPropertyDataVar,
} from "../../common/apollo/vars/user-property-data.var";
import { resetGlobalModalVar } from "../../common/apollo/vars/global-modal.var";
import { resetDockVar } from "../../common/apollo/vars/dock.var";
import { themeVar } from "../../common/apollo/vars/theme.var";

import { Spinner } from "shared/components/spinner";

import { useGetMyPropertyDataLazyQuery } from "../../generated/graphql";

import { WEB_AUTH_PROPERTY } from "shared/common/data/constants";
import { IPrimaryColorOption } from "shared/containers/primary-color-select/primary-color-select.interfaces";

import { primaryColorOptions } from "shared/containers/primary-color-select/primary-color-select.utils";
import { setLocalStorageItem } from "shared/utils/local-storage";

import defaultTheme from "shared/styles/Theme";

const PropertyProtectedRoute = ({ children }: PropsWithChildren) => {
  const { id } = useParams<{ id: string }>();

  const history = useHistory();

  const propertySwitching = useReactiveVar(propertySwitchingVar);

  const [propertySet, setProperty] = useState(false);

  const [getMyPropertyData] = useGetMyPropertyDataLazyQuery({
    onCompleted: (data) => {
      resetDockVar();
      resetGlobalModalVar();

      propertyIdVar(data.getMyPropertyData.property.id);

      propertyDataVar({
        id: data.getMyPropertyData.property.id,
        title: data.getMyPropertyData.property.title,
        timezone: data.getMyPropertyData.property.timezone,
        primaryColor: data.getMyPropertyData.property.primaryColor || null,
        bgImg: data.getMyPropertyData.property.bgImg || null,
        logo: data.getMyPropertyData.property.logo || null,
        favicon: data.getMyPropertyData.property.favicon || null,
        address: data.getMyPropertyData.property.address?.address || null,
        longitude: data.getMyPropertyData.property.address?.longitude ?? null,
        latitude: data.getMyPropertyData.property.address?.latitude ?? null,
      });

      userPropertyDataVar({
        id: data.getMyPropertyData.id,
        firstName: data.getMyPropertyData.firstName,
        lastName: data.getMyPropertyData.lastName,
        email: data.getMyPropertyData.email,
        status: data.getMyPropertyData.status,
        title: data.getMyPropertyData.title || null,
        phone: data.getMyPropertyData.phone || null,
        photo: data.getMyPropertyData.photo || null,
        role: data.getMyPropertyData.role,
        printer: data.getMyPropertyData.printer || null,
        homepageId: data.getMyPropertyData.homepageId || null,
      });

      let newColor: IPrimaryColorOption | undefined;
      if (data.getMyPropertyData.property.primaryColor) {
        newColor = primaryColorOptions.find(
          ({ value }) => value === data.getMyPropertyData.property.primaryColor
        );
      }

      themeVar({
        ...defaultTheme,
        colors: {
          ...defaultTheme.colors,
          primary: {
            ...(newColor?.pallete || defaultTheme.colors.primary),
          },
        },
      });

      setLocalStorageItem(
        WEB_AUTH_PROPERTY,
        data.getMyPropertyData.property.id
      );

      setProperty(true);
    },
    onError: (err) => {
      console.log(err);
      toast.error(err.message);

      history.push("/properties");
    },
  });

  useEffect(() => {
    return () => {
      themeVar(defaultTheme);
      resetDockVar();
      resetGlobalModalVar();
      resetPropertyIdVar();
      resetPropertyDataVar();
      resetUserPropertyDataVar();
    };
  }, []);

  useEffect(() => {
    setProperty(false);

    if (!propertySwitching) {
      getMyPropertyData({
        fetchPolicy: "no-cache",
        variables: {
          propertyId: id,
        },
      });
    }
  }, [id, getMyPropertyData, propertySwitching]);

  if (propertySwitching || !propertySet) {
    return <Spinner $height="100vh" />;
  }

  return <Fragment>{children}</Fragment>;
};

export default PropertyProtectedRoute;
